* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  background-color: #f4f6f8;
}
.recharts-wrapper * {
  outline: none !important;
}

/* Gleap widget */
.gl-block.gleap-frame-container {
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 2px 0 rgba(63, 63, 68, 0.15);
}

.bb-feedback-button .bb-feedback-button-icon {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.31), 0 2px 2px -2px rgba(0, 0, 0, 0.25);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.bb-feedback-button .bb-feedback-button-icon:hover {
  transform: none;
  background-color: rgb(0, 143, 98);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.31), 0 3px 4px -2px rgba(0, 0, 0, 0.25);
}
